import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/OnboardingFlow.css';  // This line imports the CSS styles

const OnboardingFlow = () => {
  const [communityName, setCommunityName] = useState('');
  const [communityType, setCommunityType] = useState('');
  const [businessDescriptor, setBusinessDescriptor] = useState(''); // Add this line
  const [availability, setAvailability] = useState({
    Lu: true, Ma: true, Mi: true, Ju: true, Vi: true, Sa: false, Do: false
  });
  const [openingTime, setOpeningTime] = useState('08:00 AM');
  const [closingTime, setClosingTime] = useState('10:00 PM');
  const navigate = useNavigate();

  const convertTo24Hour = (time12) => {
    if (!time12) return '';
    const [timeStr, modifier] = time12.split(' ');
    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours);
    
    if (hours === 12) {
      hours = modifier === 'PM' ? 12 : 0;
    } else {
      hours = modifier === 'PM' ? hours + 12 : hours;
    }
    
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  const convertTo12Hour = (time24) => {
    if (!time24) return '';
    const [hours, minutes] = time24.split(':');
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  };

  const [timeError, setTimeError] = useState('');

  const validateTimes = (open, close) => {
    const open24 = convertTo24Hour(open);
    const close24 = convertTo24Hour(close);
    
    const openHours = parseInt(open24.split(':')[0]);
    const closeHours = parseInt(close24.split(':')[0]);
    const openMinutes = parseInt(open24.split(':')[1]);
    const closeMinutes = parseInt(close24.split(':')[1]);

    if (closeHours < openHours || (closeHours === openHours && closeMinutes <= openMinutes)) {
      setTimeError('El horario de cierre debe ser posterior al horario de apertura');
      return false;
    }
    setTimeError('');
    return true;
  };

  useEffect(() => {
    const fetchCommunityData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in!');
        return;
      }

      const idToken = await user.getIdToken();
      const communityId = user.uid; // Assuming the user's UID is the community ID

      try {
        const response = await axios.get(`/api/community/details/${communityId}`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        if (response.data) {
          const { name, communityType, businessDescriptor, availability } = response.data;
          setCommunityName(name || '');
          setCommunityType(communityType || '');
          setBusinessDescriptor(businessDescriptor || '');
          if (availability) {
            if (availability.days) {
              setAvailability(availability.days);
            }
            if (availability.hours) {
              setOpeningTime(convertTo12Hour(availability.hours.open || '08:00'));
              setClosingTime(convertTo12Hour(availability.hours.close || '22:00'));
            }
          }
        }
      } catch (error) {
        console.error('Error fetching community data:', error);
      }
    };

    fetchCommunityData();
  }, []);

  const handleAvailabilityChange = (day) => {
    setAvailability(prev => ({ ...prev, [day]: !prev[day] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateTimes(openingTime, closingTime)) {
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    user.getIdToken().then(async (idToken) => {
      const communityId = user.uid;
  
      try {
        const response = await axios.post('/api/community/update', {
          communityId,
          communityName,
          communityType,
          businessDescriptor,
          availability: {
            days: availability,
            hours: {
              open: convertTo24Hour(openingTime),
              close: convertTo24Hour(closingTime)
            }
          }
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
  
        if (response.status === 200) {
          console.log('Community updated successfully');
          navigate('/profile');
        } else {
          console.error('Failed to update community data');
        }
      } catch (error) {
        console.error('Error submitting community updates:', error);
      }
    }).catch(error => {
      console.error('Failed to get Firebase ID token', error);
    });
  };

  const formatTimeForDisplay = (time12) => {
    if (!time12) return '';
    // Remove any leading zeros from hours
    const [timeStr, modifier] = time12.split(' ');
    const [hours, minutes] = timeStr.split(':');
    return `${parseInt(hours)}:${minutes} ${modifier}`;
  };

  const validateTimeFormat = (timeStr) => {
    // Regular expression to match "HH:MM AM/PM" format
    const timeRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/i;
    return timeRegex.test(timeStr);
  };

  return (
    <div className="onboarding-container">
      <div className="header">
        <button onClick={() => navigate(-1)} className="back-button">Volver</button>
        <h1 className="onboarding-title">Información del negocio</h1>
      </div>
      <form onSubmit={handleSubmit} className="onboarding-form">
        <div className="input-group">
          <label htmlFor="communityName">Nombre de tu negocio</label>
          <input
            type="text"
            id="communityName"
            value={communityName}
            onChange={e => setCommunityName(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="communityType">Categoría</label>
          <select
            id="communityType"
            value={communityType}
            onChange={e => setCommunityType(e.target.value)}
            className="type-select"
            required
          >
            <option value="">Selecciona una opción...</option>
            <option value="Wellness">Wellness</option>
            <option value="Fitness">Fitness</option>
            <option value="Servicios académicos">Servicios académicos</option>
            <option value="Artes">Artes</option>
            <option value="Salud">Salud</option>
            <option value="Mantenimiento">Mantenimiento</option>
            <option value="Servicios profesionales">Servicios profesionales</option>
            <option value="Startup">Startup</option>
            <option value="Otros">Otros</option>
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="businessDescriptor">Giro del negocio (opcional)</label>
          <input
            type="text"
            id="businessDescriptor"
            value={businessDescriptor}
            onChange={e => setBusinessDescriptor(e.target.value)}
            placeholder="Ej. Estudio de yoga, diseñador freelance"
          />
        </div>

        <div className="availability-section">
          <h2>DISPONIBILIDAD Y HORARIOS</h2>
          <div className="availability-days">
            <p>Disponibilidad de días</p>
            <div className="day-buttons">
              {['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'].map(day => (
                <button
                  key={day}
                  type="button"
                  className={`day-button ${availability[day] ? 'active' : ''}`}
                  onClick={() => handleAvailabilityChange(day)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
          <div className="availability-hours">
            <div>
              <label htmlFor="openingTime">Horario de apertura</label>
              <input
                type="text"
                id="openingTime"
                value={formatTimeForDisplay(openingTime)}
                placeholder="e.g. 8:00 AM"
                onChange={(e) => {
                  const newTime = e.target.value.toUpperCase();
                  setOpeningTime(newTime);
                  if (validateTimeFormat(newTime)) {
                    validateTimes(newTime, closingTime);
                  }
                }}
                onBlur={(e) => {
                  if (!validateTimeFormat(e.target.value)) {
                    setTimeError('Por favor ingresa un horario válido (e.g. 8:00 AM)');
                  }
                }}
              />
            </div>
            <div>
              <label htmlFor="closingTime">Horario de cierre</label>
              <input
                type="text"
                id="closingTime"
                value={formatTimeForDisplay(closingTime)}
                placeholder="e.g. 10:00 PM"
                onChange={(e) => {
                  const newTime = e.target.value.toUpperCase();
                  setClosingTime(newTime);
                  if (validateTimeFormat(newTime)) {
                    validateTimes(openingTime, newTime);
                  }
                }}
                onBlur={(e) => {
                  if (!validateTimeFormat(e.target.value)) {
                    setTimeError('Por favor ingresa un horario válido (e.g. 10:00 PM)');
                  }
                }}
              />
            </div>
            {timeError && <div className="time-error">{timeError}</div>}
          </div>
        </div>

        <div className="save-button-container">
          <button type="submit" className="save-button btn btn-primary">Guardar información</button>
        </div>
      </form>
    </div>
  );
};

export default OnboardingFlow;